import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getStorage, connectStorageEmulator } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyD4eTEd3_zDl8I98Kbr7sgKgnf0MhS24W0",
  authDomain: "ipah-8503d.firebaseapp.com",
  projectId: "ipah-8503d",
  storageBucket: "ipah-8503d.appspot.com",
  messagingSenderId: "633640517727",
  appId: "1:633640517727:web:2ab5c004b49069c34e5bea",
  measurementId: "G-07H3LBY82J",
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const auth = getAuth();
connectAuthEmulator(auth, "http://127.0.0.1:9099");

const db = getFirestore();
connectFirestoreEmulator(db, "127.0.0.1", 8080);

const storage = getStorage();
connectStorageEmulator(storage, "127.0.0.1", 9199);

export { app, analytics, auth, db, storage };
