import React, { useState } from 'react';
import { user } from 'rxfire/auth';
import { GoogleAuthProvider, FacebookAuthProvider, EmailAuthProvider, User, signOut } from 'firebase/auth';
import { auth } from './firebase';
import FirebaseAuth from './components/FirebaseAuth';

// Configure FirebaseUI.
const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: 'popup',
  // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
  signInSuccessUrl: '/signedIn',
  // We will display Google and Facebook as auth providers.
  signInOptions: [
    EmailAuthProvider.PROVIDER_ID,
    GoogleAuthProvider.PROVIDER_ID,
    FacebookAuthProvider.PROVIDER_ID,
  ],
};

function signOutComponent() {
  function signOutUser() {
    signOut(auth);
  }
  return <button onClick={signOutUser}>Sign out</button>;
}


function App() {
  const [userObj, setUserObj] = useState<User | null>();
  user(auth).subscribe((user: User | null) => { console.log(user); setUserObj(user); });
  const loginComponent = userObj ? <FirebaseAuth uiConfig={uiConfig} firebaseAuth={auth} /> : signOutComponent();
  return (
    <div className="App">
      <header className="App-header">
        <p>
          Edit <code>src/App.tsx</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
      {loginComponent}
    </div>
  );
}

export default App;
